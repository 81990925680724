// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.
//

import { createConsumer } from "@rails/actioncable";

let consumer;

export const createCable = () => {
  if (!consumer) {
    consumer = createConsumer();
  }

  return consumer;
};

export const createChannel = (...args) => {
  const consumer = createCable();
  return consumer.subscriptions.create(...args);
};