/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import 'core-js/stable'
// import 'regenerator-runtime/runtime'


const Rails = require("@rails/ujs");
Rails.start();
window.Rails = Rails;
require("bootstrap");
require("chart.js");
require("@nathanvda/cocoon");

const countUp = require("countup.js");
window.countUp = countUp;
import Swiper from "swiper";

window.Swiper = Swiper;

import { createCable, createChannel } from "./cable.js";
window.Cable = createCable();

import ClassicEditor from '@eostudio/ckeditor5-build-eostudio/build/ckeditor';
window.ClassicEditor = ClassicEditor;

(function ($) {

  $.fn.pinned = function (options) {
    var defaults = {
      // bounds: '0px',
      start: '0px',
      end: '0px',
      scrolling: '0px',
      mobile: false
    }

    return this.each(function () {

      var settings = $.extend(defaults, options);

      var $this = $(this);
      var orig = $this.css('top');
      $this.data('pinned', true);

      var windowScroll = function () {
        if ($this.data('pinned')){
          $(window).scroll(function () {
            
            // let notBottom = (($(window).scrollTop() + $this.height()) < ($("body").height() - 500));
            // if ($(window).width() >= 992 && notBottom){
            //   if (($(window).scrollTop() < settings.bounds)) {
            //     console.log("here1")
            //     // $this.css({ 'position': 'absolute', 'top': settings.scrolling + $(window).scrollTop(), 'width': $this.parent().width() });
            //     $this.css({ 'position': 'fixed', 'top': 100, 'width': $this.parent().width() });
            //   }
              
            // } else {
            //   if ($(window).width() >= 992) {
            //     console.log("here2")
            //     let difference = settings.scrolling - (($(window).scrollTop() + $this.height()) - ($("body").height() - 500));
            //     $this.css({ 'position': 'fixed', 'top': difference, 'width': $this.parent().width() });
            //   } else {
            //     console.log("here3")
            //     $this.css({ 'position': '', 'top': orig, 'width': $this.parent().width() });
            //   }
            // }
            if ($(window).width() >= 992){
              if (($(window).scrollTop() >= settings.start && $(window).scrollTop() < (settings.end - $this.height()))) {
                // $this.css({ 'position': 'absolute', 'top': ($(window).scrollTop() - $this.height() + 100), 'width': $this.parent().width() });
                $this.css({ 'position': 'fixed', 'top': (100 + settings.scrolling ), 'bottom': 'unset',  'width': $this.parent().width() });
              } else if ($(window).scrollTop() >= (settings.end - ($this.height() + 100 ))) {
                $this.css({ 'position': 'absolute', 'top': "unset", 'bottom': (0), 'width': $this.parent().width() });
              } else {
                $this.css({ 'position': '', 'top': orig,  'bottom': 'unset', 'width': $this.parent().width() });
              }
              
            } 
          });
        }
      }

      // TODO - Add better support for mobile devices on scroll
      var mobileScroll = function () {
        if ($this.data('pinned', true)){
          $(window).bind('touchmove, scroll', function () {
            // if ($(window).width() <= 992){
            //   if ($(window).scrollTop() > settings.bounds && $this.css('position') != 'fixed') {
            //     $this.css({ 'position': 'fixed', 'top': settings.scrolling, 'width': $this.parent().width() });
            //   }
            //   if ($(window).scrollTop() < settings.bounds && $this.css('position') != 'absolute') {
            //     $this.css({ 'position': '', 'top': orig, 'width': $this.parent().width() });
            //   }
            // } else {
            //     $this.css({ 'position': '', 'top': orig, 'width': $this.parent().width() });
            // }
            if ($(window).width() <= 992){
              if (($(window).scrollTop() >= settings.start && $(window).scrollTop() < (settings.end - $this.height()))) {
                console.log("here1")
                // $this.css({ 'position': 'absolute', 'top': ($(window).scrollTop() - $this.height() + 100), 'width': $this.parent().width() });
                $this.css({ 'position': 'fixed', 'top': (100 + settings.scrolling), 'bottom': 'unset',  'width': $this.parent().width() });
              } else if ($(window).scrollTop() >= (settings.end - ($this.height() + 100))) {
                console.log("here2")
                $this.css({ 'position': 'absolute', 'top': "unset", 'bottom': (0), 'width': $this.parent().width() });
              } else {
                console.log("here3")
                $this.css({ 'position': '', 'top': orig,  'bottom': 'unset', 'width': $this.parent().width() });
              }
            }
          });
        }
      }

      var isMobile = function () {
        if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPad/i)) || settings.mobile) {
          return true;
        }
        else {
          return false;
        }
      }


      function init() {
        if (isMobile() && settings.mobile == false || !isMobile()) {
          windowScroll();
        } else {
          mobileScroll();
        }
      }
      init();
    });
  };

})(jQuery);

class UploadAdapter {
  constructor(loader) {
      this.loader = loader;
  }

  upload() {
      return this.loader.file.then( file => new Promise(((resolve, reject) => {
          this._initRequest();
          this._initListeners( resolve, reject, file );
          this._sendRequest( file );
      })))
  }

  _initRequest() {
      const xhr = this.xhr = new XMLHttpRequest();
      xhr.open('POST', '/posts/upload_post_image', true);
      xhr.responseType = 'json';
  }

  _initListeners(resolve, reject, file) {
      const xhr = this.xhr;
      const loader = this.loader;
      const genericErrorText = '파일을 업로드 할 수 없습니다.'

      xhr.addEventListener('error', () => {reject(genericErrorText)})
      xhr.addEventListener('abort', () => reject())
      xhr.addEventListener('load', () => {
          const response = xhr.response

          if(!response || response.error) {
            return reject( response && response.error ? response.error.message : genericErrorText );
          }
          resolve({
            default: response.url //업로드된 파일 주소
          })
      })
  }

  _sendRequest(file) {
      const data = new FormData()
      data.append('upload',file)
      this.xhr.send(data)
  }
}

window.UploadAdapter = UploadAdapter;

// import { getLinkPreview, getPreviewFromContent } from "link-preview-js";
// window.getLinkPreview = getLinkPreview;
// window.getPreviewFromContent = getPreviewFromContent;